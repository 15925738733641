import React from 'react';
import { css } from '@emotion/core';
import Link from './Link';
import { CtaThemes, CtaSizes, CtaBase, CtaDisabled } from '../../styles/cta';
import { CtaSizesType, CtaThemesType } from '../../appTypes';

interface CtaProps {
  size?: CtaSizesType;
  disabled?: boolean;
  theme?: CtaThemesType;
  to: string;
  children: string;
}

const Cta: React.FunctionComponent<CtaProps> = ({
  children,
  to,
  size = 'M',
  theme = 'primary',
  disabled = false,
  ...rest
}) => {
  return (
    <Link
      data-text={children}
      css={css`
        position: relative;
        ${CtaBase};
        ${CtaSizes[size]}
        ${CtaThemes[theme]}
        ${disabled && CtaDisabled}
      `}
      to={to}
      {...rest}
    >
      <span>{children}</span>
    </Link>
  );
};

export default Cta;
