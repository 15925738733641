import { css, SerializedStyles } from '@emotion/core';
import { CtaSizesType, CtaThemesType } from '../appTypes';
import {
  State,
  Color,
  FontSize,
  TransitionTiming,
  FontWeight,
} from '../enums/appStyles';

const linkState = '&:hover, &:focus, &:active';

export const CtaThemes: { [key in CtaThemesType]: SerializedStyles } = {
  primary: css`
    color: ${Color.NeutralDarkest};
    background: ${Color.Clr1};
    border-color: ${Color.Clr1};
    ${linkState} {
      color: ${Color.NeutralDarkest};
      background: ${Color.Clr1Light};
      border-color: ${Color.Clr1Light};
    }
  `,
  neutral: css`
    color: ${Color.NeutralDarkest};
    background: transparent;
    border-color: ${Color.Clr1Light};
    ${linkState} {
      color: ${Color.NeutralDarkest};
      background: ${Color.Clr1Light};
      border-color: ${Color.Clr1Light};
    }
  `,
  neutralDark: css`
    color: ${Color.Clr1};
    background: transparent;
    border-color: ${Color.Clr1Light};
    ${linkState} {
      color: ${Color.Light};
      background: ${Color.NeutralDarkest};
      border-color: ${Color.Clr1Light};
      &:after {
        color: ${Color.Light};
      }
    }
  `,
};

export const CtaSizes: { [key in CtaSizesType]: SerializedStyles } = {
  M: css`
    font-size: inherit;
    span,
    &:after {
      min-height: 4.8rem;
      padding: 1.6rem 3.6rem 1.2rem;
    }
  `,
  S: css`
    font-size: ${FontSize.XS};
    span,
    &:after {
      min-height: 3.2rem;
      padding: 1rem 2.4rem 0.6rem;
    }
  `,
};

export const CtaBase = css`
  display: inline-flex;
  text-align: center;
  text-decoration: none;
  font-family: inherit;
  line-height: 1;
  border-radius: 66rem;
  font-weight: ${FontWeight.Medium};
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  user-select: none;
  transition: all 0.2s ${TransitionTiming.base};

  &:after {
    z-index: 2;
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    opacity: 0;
    color: ${Color.NeutralDarkest};
    transform: translate3d(0, 25%, 0);
    transition: all 0.3s ease-in-out;
  }
  span {
    position: relative;
    display: block;
    z-index: 3;
    transition: all 0.3s ease-in-out;
  }
  ${linkState} {
    box-shadow: 0 0 20px rgba(0, 230, 180, 0.46),
      0 0 6px rgba(0, 230, 180, 0.46);
    span {
      opacity: 0;
      transform: translate3d(0, -25%, 0);
    }
    &:after {
      opacity: 1;
      transform: translate3d(0, 0%, 0);
    }
  }
`;

export const CtaDisabled = css`
  opacity: ${State.DisabledOpacity};
  pointer-events: none;
`;
