import React from 'react';
import styled from '@emotion/styled';
import Wrapper from '../layout/Wrapper';
import Cta from '../base/Cta';
import {
  Color,
  FontSize,
  FontWeight,
  MqUp,
  Breakpoint,
} from '../../enums/appStyles';

const ContactEl = styled.div`
  padding: 8rem 0;
  ${MqUp(Breakpoint.Smartphone)} {
    padding: 14rem 0;
  }
  text-align: center;
  background: ${Color.NeutralDarkest};
  color: ${Color.Light};
`;

const Title = styled.div`
  font-size: ${FontSize.XL};
  text-transform: uppercase;
  font-weight: ${FontWeight.Bold};
  margin: 0.9rem 0 1.4rem;
  ${MqUp(Breakpoint.Smartphone)} {
    font-size: ${FontSize.XXL};
  }
`;

const Text = styled.p`
  margin-bottom: 3.2rem;
  ${MqUp(Breakpoint.Smartphone)} {
    font-size: ${FontSize.M};
  }
`;

const Contact = ({ ...rest }) => (
  <ContactEl id="contact" {...rest}>
    <Wrapper>
      <Title>Travaillons ensemble</Title>
      <Text>
        Vous avez un projet et vous pensez que nous pouvons travailler
        ensemble&nbsp;?
        <br />
        N’hésitez pas à me contacter.
      </Text>
      <Cta to="mailto:laurent@sutterlity.studio">laurent@sutterlity.studio</Cta>
    </Wrapper>
  </ContactEl>
);

export default Contact;
