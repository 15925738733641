import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { useStaticQuery, graphql } from 'gatsby';
import Link from '../base/Link';
import Wrapper from './Wrapper';
import { Color } from '../../enums/appStyles';
import Contact from '../base/Contact';

interface FooterProps {
  props?: object;
}

const Container = styled.div`
  text-align: center;
  background: ${Color.NeutralDarkest};
  color: ${Color.NeutralLighter};
  a {
    text-decoration: none;
  }
`;

const AppFooter: React.FunctionComponent<FooterProps> = props => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            author
            twitterUser
            linkedinUrl
            githubUrl
            dribbbleUrl
          }
        }
      }
    `,
  );
  return (
    <footer>
      <Contact />
      <Container {...props}>
        <Wrapper
          css={css`
            border-top: 0.8rem solid ${Color.NeutralDark};
            padding-top: 4rem;
            padding-bottom: 4rem;
          `}
        >
          <p
            css={css`
              margin-bottom: 1.6rem;
              color: rgba(255, 255, 255, 0.25);
            `}
          >
            <Link to={site.siteMetadata.linkedinUrl}>Linkedin</Link>
            {'  /  '}
            <Link
              to={`https://twitter.com/${site.siteMetadata.twitterUser.slice(
                1,
              )}`}
            >
              Twitter
            </Link>
            {'  /  '}
            <Link to={site.siteMetadata.githubUrl}>Github</Link>
            {'  /  '}
            <Link to={site.siteMetadata.dribbbleUrl}>Dribbble</Link>
          </p>
          <p>&copy;&nbsp;2020 Sutterlity</p>
        </Wrapper>
      </Container>
    </footer>
  );
};

export default AppFooter;
