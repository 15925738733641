import React from 'react';
import styled from '@emotion/styled';
import { useStaticQuery, graphql } from 'gatsby';
import { Global, css } from '@emotion/core';
import SEO from './Seo';
import AppFooter from './AppFooter';
import base from '../../styles/base';
import normalize from '../../styles/normalize';

const Container = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`;

const AppMain = styled.div`
  flex-grow: 1;
`;

interface AppProps {
  title?: string;
  description?: string;
  image?: string;
  children: React.ReactNode;
  language?: string;
}

const App: React.FunctionComponent<AppProps> = props => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            language
          }
        }
      }
    `,
  );
  const { title, description, children } = props;
  return (
    <Container>
      <SEO
        title={title}
        description={description}
        lang={site.siteMetadata.language}
      />
      <Global
        styles={css`
          ${normalize}
          ${base}
        `}
      />
      {/* <AppHeader
        toggleNavMobile={toggleNavMobile}
        closeNavMobile={closeNavMobile}
        navOpen={navMobileOpen}
      /> */}
      <AppMain>{children}</AppMain>
      <AppFooter />
    </Container>
  );
};

export default App;
