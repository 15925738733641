export enum Color {
  Light = '#fff',

  NeutralDarkest = '#111314',
  NeutralDarker = '#141617',
  NeutralDark = '#1a1c1d',
  Neutral = '#232526',
  NeutralLight = '#a9a9aa',
  NeutralLighter = '#f2f2f3',
  NeutralLightest = '#f2f2f3',

  Clr1Darkest = '#006e54',
  Clr1Darker = '#009874',
  Clr1Dark = '#00b78c',
  Clr1 = '#00e6b4',
  Clr1Light = '#4bedc7',
  Clr1Lighter = '#a6f6e3',
  Clr1Lightest = '#e5fcf6',

  BorderInput = 'hsla(0, 0%, 13%, 0.125)',
  BorderInputDark = 'hsla(0, 0%, 13%, 0.25)',
}

export enum FontFamily {
  Primary = '"Hind", serif',
  Code = "Consolas, 'Roboto Mono', 'Liberation Mono', Menlo, Courier,",
}

export enum FontWeight {
  Light = 300,
  Regular = 400,
  Medium = 500,
  Bold = 700,
}

export enum FontSize {
  XXS = '1.2rem',
  XS = '1.4rem',
  S = '1.6rem',
  M = '1.8rem',
  L = '2rem',
  XL = '2.4rem',
  XXL = '3.2rem',
  XXXL = '4.8rem',
}

export enum LineHeight {
  XS = 1,
  S = 1.15,
  M = 1.25,
  L = 1.5,
  XL = 1.625,
}
export enum Radius {
  XS = '.2rem',
  S = '.4rem',
}

export enum TransitionTiming {
  base = 'ease-in-out',
}

export enum State {
  DisabledOpacity = 0.25,
}

export enum Breakpoint {
  Smartphone = 480,
  Tablet = 768,
  Desktop = 1024,
  DesktopLarge = 1280,
  MainNav = 666,
}

export const MqUp = (size: Breakpoint) => `@media (min-width: ${size}px)`;
export const MqDown = (size: Breakpoint) =>
  `@media (max-width: ${size + -1}px)`;

export enum Header {
  HeightBase = '10rem',
  HeightSmall = '4rem',
}
